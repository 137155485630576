import React from "react";
import { useLanguage } from "./LanguageContext";
import English from "./Swedish"; // Import language-specific text objects
import Swedish from "./English";
import German from "./German";
function FourthComp() {
  const { language } = useLanguage();
  let texts;
  switch (language) {
    case "swedish":
      texts = English;
      break;
    case "german":
      texts = German;
      break;
    default:
      texts = Swedish;
      break;
  }
  const { MC, cykel, mountainbike } = texts || {};
  return (
    <div className='MCCon' id='MCCon'>
      <div className='image mc'></div>
      <div className="absolute">
        
      </div>
      <div className='MCConFirstChild'>
        <h2>{MC.title}</h2>
        {/* <h3>{MC.underTitle}</h3> */}
        {/* <div className='fourthCompSecondChild'>
          <p>{MC.text}</p>
          <p>{MC.text2}</p>
        </div> */}
      </div>
      <div className='MCConFirstChild'>
        <h3>{cykel.title}</h3>
        <div className='fourthCompSecondChild'>
          <p>{cykel.text}</p>
        </div>
      </div>
      <div className='MCConFirstChild'>
        <h3>{mountainbike.title}</h3>
        <div className='fourthCompSecondChild'>
          <p>{mountainbike.text}</p>
          <p>{mountainbike.text2}</p>
        </div>
      </div>
    </div>
  );
}

export default FourthComp;
