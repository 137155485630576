const English = {
  title:
    "The campground for YOU with an interest in nature-based experiences! The campground has a personal and cost-positive profile.",
  title2:
    "With us, you can pitch your tent, caravan, or motorhome - but you can also rent a comfortable mobile home.",
  attGora: {
    mccamp:
      "MC-camp - For those who enjoy motorcycle cruising on excellent forest roads with exciting rest areas",
    bilvandring:
      "Car/hiking - You can stay overnight at the camp, park your car, get information, or prepare food",
    mountainbike:
      "Mountain biking - You can securely store your bike overnight. There are great forest trails to ride on",
    fiske:
      "Fishing - Near excellent fishing lake + Lake Vättern and many other fishing spots",
    goldenbay:
      "Golden-Bay - Dogs are welcome, and you can have fun and effective water training",
    horsedriving:
      "Horse-driving - Grazing field for your horse and space to set up your carriage",
    funktionsstod:
      "Disability support - Practical support is available for those physically mobile but with other disabilities",
    service:
      "Services - Need to do laundry or dry clothes? There are washing machines and drying facilities",
    web: "Internet - You can borrow a computer or connect yourself to the web",
  },
  stod: "We have professional experience in factors of nature that promote vitality and connection for persons with disabilities. For guests with qualified healthcare needs, we can provide support up to the level of a district nurse.",
  fotvard:
    "When our feet take us out on exciting nature adventures, they may need extra care. At the camp, you can book an appointment with an experienced and well-trained foot care specialist. Advanced foot care including wound care can be provided up to the level of a nurse.",
  overnattning: {
    title: "Budget-friendly overnight stay!",
    underTitle:
      "Tent, caravan, motorhome – bring your own! You can also rent a comfortable caravan or mobile home! Explore exciting marked trails in the primeval forest of Tiveden.",
    aktiviteter: {
      mccamp: "MC-camp - Parking space for your motorcycle",
      mountainbike: "Mountainbike - You can lock up your bike",
      cykel: "Side-by-side bike - For those with disabilities",
      horsedriving:
        "Horse-driving - Parking and grazing for horse and carriage",
      goldenBay: "GoldenBay - Water activities with your dog",
    },
    title2: {
      title: "Camping 'light'",
      text: "Access to hygiene, laundry, and drying facilities. Outdoor food; rain- and wind-protected dining area. Children's island; play and adventure for the young ones.",
    },
  },
  MC: {
    title: "MC, mountain bike, bicycle",
    underTitle: "Summary",
    text: "From an ecological perspective, the bicycle is the most prominent mode of transportation. With a standard pedal bike, we cover the longest distances with minimal muscular energy. The bicycle has undergone enormous development – and the mountain bikes we see today are far from their early origins. The motorcycle has evolved from the early lightweight models into monumental cruisers or race machines with incredible top speeds.",
    text2:
      "At Tivedsportens Eco Camp, there are special arrangements for cycling, mountain biking, and motorcycling with options for basic service and repairs, as well as vehicle storage in a secure area.",
  },
  cykel: {
    title: "Bicycle",
    text: "The Sweden Trail, which runs from North to South through the entire country, passes and branches out right in Håkantorp; Tivedsportens Eco Camp. Heading north, you can cycle towards Laxå, Karlskoga, and further up through the country to Norrbotten. Heading east, you'll reach Askersund, Örebro, and the east coast including our capital; Stockholm. Heading south, the journey takes you through Tived, Falköping, down towards the southernmost part of Sweden. To the west, the Sweden Trail leads to Mariestad, Vänersborg, and up towards the Norwegian border.",
  },
  mountainbike: {
    title: "Mountain Bike & Motorcycle",
    text: "For the very valuable bikes, there is a lockable space developed in collaboration with mountain bike enthusiasts. In a simple hygiene area, there are facilities for showering, washing, and drying clothes.",
    text2:
      "The road through Håkantorp down towards Tived is a very popular motorcycle route. When we bought the mill cottage in Håkantorp, our intention was to create an activity site for light outdoor life in Tiveden - and motorcyclists are a large group of visitors to the various attractions in the primeval forest of Tiveden. We have designated parking spaces for motorcycles next to which you can pitch your tent.",
  },
  animal: {
    title: "Tivedsporten Dog and Horse",
    hundTitle: "Dog",
    textHund:
      "Tivedsporten is located by a small watercourse – a former millpond – which is great for training retrieval and physical exercises for dogs – hence Golden-Bay – as we ourselves have a Golden Retriever.",
    hastTitle: "Horse",
    textHast:
      "Tivedsporten includes a small field area + a grove that has been fenced off for horses. There are rain and wind shelters. Tiveden has several suitable routes for horse-drawn vehicles such as carriage driving and four-wheelers. We do not have the capability to stable horses ourselves – but can in some cases facilitate contact. We can also facilitate contact with a veterinarian.",
  },
  vandra: {
    title: "Hiking",
    text: "Hiking at Tivedsporten; The trails/routes are marked on the large information board at Tivedsportens Eco Camp. You can also get printed hiking instructions or visit our IT cabin and search for information online. There are a total of 23 different hiking trails nearby.",
  },
  stod: {
    title: "Functional Support",
    text: "We work with Nature Tourism, which means that everyone should have the opportunity to experience nature in a way that combines excitement with humility and responsibility. Tivedsporten; Eco-Camp caters to traditional tourists but also outdoor enthusiasts with disabilities – particularly those with cognitive impairments. Cognitive impairments involve various mental/thought difficulties. Within Tivedsporten, there is professional expertise, especially regarding age-related functional changes - providing support for them and their families to have a positive nature experience.",
  },
  fiske: {
    title: "Fishing",
    text: "Tivedsporten is situated where the Tived forests begin, with numerous watercourses. It is close to the most fish-rich and beautiful parts of Lake Vättern's archipelago. Since the summer of 2014, we have been issuing fishing permits. If you need to set up a trolling boat or leave other fishing equipment, there is space for that. When you use Tivedsportens Eco Camp as your base, you have easy access to all forms of fishing, beautiful scenery, and only five kilometers from the cozy little harbor town of Askersund! Tivedsportens Eco Camp collaborates with and supports iFiske.",
  },
  kontakt: {
    title: "Feel free to contact me for more information",
    tel: "Telephone",
    epost: "Email",
  },
  navbar: {
    hem: "Home",
    aktiviteter: "Activities",
    information: "Information",
    mc: "MC, Mountain Bike & Bicycle",
    animal: "Dog & Horse",
    hike: "Hiking",
    support: "Functional Support",
    fishing: "Fishing",
    contact: "Contact",
  },
};
export default English;
