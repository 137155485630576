const Swedish = {
  title:
    "Campingen för DIG med intresse för naturnära upplevelser! Campingen har en personlig och kostnadspositiv profil.",
  title2:
    "Hos oss kan du ställa upp ditt tält, husvagn, husbil - men du kan också hyra bekväm villavagn. ",
  attGora: {
    mccamp:
      "MC-camp - För dig som gillar MC-crusing på utmärkta skogsvägar med spännande rastplatser",
    bilvandring:
      "Bil/vandring  - Du kan övernatta på campen, parkera bilen, få information eller laga mat",
    mountainbike:
      "Mountainbike - Du kan låsa in cykeln över natten. Det finns fina skogsvägar att cykla på",
    fiske:
      "Fiske - Nära kanonfin fiskesjö + Vättern och många andra platser att fiska på",
    goldenbay:
      "Golden-Bay - Hundar är välkomna och ni kan ha rolig och effektiv vattenträning",
    horsedriving:
      "Horse-driving - Du har betesfålla för Din häst och Du kan ställa upp din körvagn",
    funktionsstod:
      "Funktionsstöd - Praktiskt stöd finns för Dig som är fysisk rörlig men har annan funktionsnedsättning",
    service:
      "Service - Du behöver tvätta eller torka? Det finns tvättmaskin och torkutrymme",
    web: "Webben - Du kan du låna dator eller koppla upp dig själv mot webben",
  },
  stod: "Vi har professionell erfarenhet av de faktorer i mötet med naturen som lyfter fram friskhet och sammananhang för personer med funktionsnedsättningar. För gäster med kvalificerat vård- och omsorgsbehov kan vi erbjuda stöd upp till distriktssköterskenivå. ",
  fotvard:
    "När fötterna ska bära oss ut på spännande naturupplevelse i naturen kan de behöva extra omsorg. På Campen finns möjlighet att boka tid hos erfaren och välutbildad fotvårdsspecialist. Avancerad fotvård inklusive sårvård kan erbjudas upp till sjukskötereskenivå. ",
  overnattning: {
    title: "Plånboksvänlig övernattning!",
    underTitle:
      "Tält, husvagn, campingbuss – ta med Din egen! Du kan också hyra en bekväm husvagn eller villavagn! Möt spännande markerade stigar i Tivedens urskog.",
    aktiviteter: {
      mccamp: "MC-camp - Ställplats för Din motorcykel",
      mountainbike: "Mountainbike - Du kan låsa in cykeln",
      cykel: "Side-by-side cykel - Du med funktionsnedsättning",
      horsedriving: "Horsedriving - Ställplats o bete för häst och vagn",
      goldenBay: "GoldenBay - Din hund och vattenapportering",
    },
    title2: {
      title: "Camping ”light”",
      text: "Du får tillgång till hygien-, tvätt- och torkutrymme. Outdoor food; regn- och vindskyddad matplats. Barnens ö; lek- och äventyr för unga.",
    },
  },
  MC: {
    title: "MC, mountainbike, cykel",
    underTitle: "Sammanfattning",
    text: "Ur ett ekologiskt perspektiv är cykeln det mest framstående transportmedlet. Med en vanlig trampcykel den tar vi oss längst sträcka med minsta möjliga muskelenergi. Cykeln har haft en enorm utveckling – och de mountainbikes vi ser idag är långt från det tidiga ursprunget. Motorcykeln har från den tidiga lättviktaren utvecklats till monumentala vägkryssare eller racermaskiner med otroliga toppfarter.",
    text2:
      "På Tivedsportens Eco Camp finns särskilda arrangemang för Cykel, Moutainbike och Motorcykel med möjlighet till enklare service och reparation samt möjlighet att förvara fordonet i låst utrymme.",
  },
  cykel: {
    title: "Cykel",
    text: "Sverigeleden som går från Norr till Söder genom hela landet passerar och grenar sig just i Håkantorp; Tivedstorpens Eco Camp. Norrut kan Du cykla mot Laxå, Karlskoga och vidare upp genom landet till Norrbotten. Österut kommer du till Askersund, Örebro och östkustenoch vår huvudstad; Stockholm. Söderut går färden genom Tived, Falköping, ner mot sydligaste Sverige. I Väster leder Sverigleden till Mariestad, Vänersborg och upp mot Norska gränsen.",
  },
  mountainbike: {
    title: "Mountainbike & Motorcykel",
    text: "För de mycket dyrbara cyklarna finns låsbart utrymme som tagits fram i samverkan med mountainbikeentusiaster. I ett enkelt hygienutrymme finns möjlighet att duscha och tvätta samt torka kläder.",
    text2:
      "Vägen genom Håkantorp ner mot Tived är en mycket populär MC-led. När vi köpte kvarnstugan i Håkantorp, var vår avsikt att skapa en aktivitetsplats för det lätta friluftslivet i Tiveden - och MC-åkarna är en stor grupp av besökarna till de olika sevärdheterna i Tivedens urskog. Vi har gjort ställplatser för motorcyklar intill vilka du kan placera Ditt tält.",
  },
  animal: {
    title: "Tivedsporten Hund och häst",
    hundTitle: "Hund",
    textHund:
      "Tivedsporten ligger vid ett litet vattendrag – en före detta kvarndam – som passar bra för att träna aportering och fysik för hundar – därav Golden-bay – eftersom vi själva har Golden Retriver.",
    hastTitle: "Häst",
    textHast:
      "Till Tivedsporten hör ett mindre åkerområde + en skogsdunge som stängslats för häst. Det finns regn och vindskydd.Tiveden har ett antal lämpliga körvägar för häst för t.ex. parkörning och fyrahjulsvagn. Vi har inte själva möjlighet till uppstallning av häst  – men kan i vissa fall förmedla kontakt. Vi kan även förmedla kontakt med veterinär.",
  },
  vandra: {
    title: "Vandring",
    text: "Vandring Tivedsporten; Stigarna/lederna finns anslagna på Tivesorten Eco Camps stora informationstavla. Du kan också få utskrivna vandringsanvisningar eller gå in i vår IT-stuga och söka information på nätet. Det finns hela 23 stycken olika vandringsleder i närheten",
  },
  stod: {
    title: "Funktionsstöd",
    text: "Vi arbetar med Naturturism som betyder att alla ska få möjlighet att uppleva naturen på ett sätt som kombinerar spänning med ödmjukhet och ansvar. Tivedsporten; Eco-Camp arbetar med traditionell turism men också för friluftsmänniskor med funktionsnedsättningar – och i synnerhet personer med kognitiva problem. Kognitiva problem är annorlunda sinnes/tankesvårigheter. Inom Tivedsporten finns professionell komptens i synnerhet när det gäller förändrade funktioner hos äldre - vilket stöd de och närstående kan behöva för att ha ett positivt utbyte av naturen.",
  },
  fiske: {
    title: "Fiske",
    text: "Tivedsporten ligger där tivedsskogarna börjar med mängder av olika vattendrag. Det är nära till de mest fiskrikta och vackraste delarna av Vätterns skärgård. Från sommaren 2014 förmedlar vi fiskekort. Behöver du ställa upp trollingbåten eller lämna annat material för fiske finns det utrymme för det. När du tar Tivedsporten Eco Camp som utgångspunkt, har du nära till alla former av fiske, vacker natur och bara fem km från den mysiga lilla hamnstaden Askersund! Tivedsporten Eco Camp samverkar och sponsrar iFiske.",
  },
  kontakt: {
    title: "Kontakta mig gärna för mer information",
    tel: "Telefon",
    epost: "E-post",
  },
  navbar: {
    hem: "Hem",
    aktiviteter: "Aktiviteter",
    information: "Information",
    mc: "MC, Mountaionbike & Cykel",
    animal: "Hund & Häst",
    hike: "Vandra",
    support: "Funktionsstöd",
    fishing: "Fiske",
    contact: "Kontakt",
  },
};

export default Swedish;
