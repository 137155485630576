import React from "react";
import { useLanguage } from "./LanguageContext";
import English from "./Swedish"; // Import language-specific text objects
import Swedish from "./English";
import German from "./German";

function FifthComp() {
  const { language } = useLanguage();
  let texts;
  switch (language) {
    case "swedish":
      texts = English;
      break;
    case "german":
      texts = German;
      break;
    default:
      texts = Swedish;
      break;
  }
  const { animal } = texts || {};
  return (
    <div className='animalCon' id='animalCon'>
      <div className='image animals'></div>
      <div className='animalConFirstChild'>
        <h2>{animal.title}</h2>
        <h3>{animal.hundTitle}</h3>
        <div className='fifthCompSecondChild'>
          <p>{animal.textHund}</p>
        </div>
        <h3>{animal.hastTitle}</h3>
        <div className='fifthCompSecondChild'>
          <p>{animal.textHast}</p>
        </div>
      </div>
    </div>
  );
}

export default FifthComp;
