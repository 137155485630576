import React from "react";
import { useLanguage } from "./LanguageContext";
import English from "./Swedish"; // Import language-specific text objects
import Swedish from "./English";
import German from "./German";
function Kontakt() {
  const { language } = useLanguage();
  let texts;
  switch (language) {
    case "swedish":
      texts = English;
      break;
    case "german":
      texts = German;
      break;
    default:
      texts = Swedish;
      break;
  }
  const { kontakt } = texts || {};

  return (
    <div id='kontaktCon' className='kontaktCon'>
      <h2>{kontakt.title}</h2>
      <div className='kontaktChild'>
        <h4>{kontakt.tel}: 070-783 10 02</h4>
        <h4>{kontakt.epost}: tivedsportalen@gmail.com</h4>
        <h4>696 92, Askersund</h4>
      </div>
    </div>
  );
}

export default Kontakt;
