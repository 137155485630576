import React from "react";
import { useLanguage } from "./LanguageContext";
import English from "./Swedish"; // Import language-specific text objects
import Swedish from "./English";
import German from "./German";
function EighthComp() {
  const { language } = useLanguage();
  let texts;
  switch (language) {
    case "swedish":
      texts = English;
      break;
    case "german":
      texts = German;
      break;
    default:
      texts = Swedish;
      break;
  }
  const { fiske } = texts || {};
  return (
    <div className='fiskeCon' id='fiskeCon'>
      <div className='image fiske'></div>
      <h2>{fiske.title}</h2>
      <div className='fiskeConFirstChild'>
        <p>{fiske.text}</p>
      </div>
    </div>
  );
}

export default EighthComp;
