import React from "react";
import { useLanguage } from "./LanguageContext";
import English from "./Swedish";
import Swedish from "./English";
import German from "./German";

function ThirdComp() {
  const { language } = useLanguage();
  let texts;
  switch (language) {
    case "swedish":
      texts = English;
      break;
    case "german":
      texts = German;
      break;
    default:
      texts = Swedish;
      break;
  }
  const { overnattning } = texts || {};
  return (
    <div className='thirdComp' id='informationCon'>
      <div className='thirdCompFirstChild'>
        <h1>{overnattning.title}</h1>
        <h3>{overnattning.underTitle}</h3>
        <div className='thirdCompSecondChild'>
          <p>{overnattning.aktiviteter.mccamp}</p>
          <p>{overnattning.aktiviteter.mountainbike}</p>
          <p>{overnattning.aktiviteter.cykel}</p>
          <p>{overnattning.aktiviteter.horsedriving}</p>
          <p>{overnattning.aktiviteter.goldenBay}</p>
        </div>

        <h3>{overnattning.title2.title}</h3>
        <p>{overnattning.title2.text}</p>
      </div>
    </div>
  );
}

export default ThirdComp;
