import React from "react";
import { useLanguage } from "./LanguageContext";
import English from "./Swedish"; // Import language-specific text objects
import Swedish from "./English";
import German from "./German";
function FirstComp() {
  const { language } = useLanguage();
  let texts;
  switch (language) {
    case "swedish":
      texts = English;
      break;
    case "german":
      texts = German;
      break;
    default:
      texts = Swedish;
      break;
  }
  const { title, title2, attGora } = texts || {};

  return (
    <div className='welcomePage' id='topCon'>
      <div className="front">
        
        <h1>Tivedsporten</h1>
        <h2>Eco & Camp</h2>

      <p style={{ fontWeight: "bold" }}>{title2}</p>
        
      </div>

      <div className='sim-dog'></div>

    </div>
  );
}

export default FirstComp;
