import React from "react";

function HittaHit() {
  return (
    <>
      <div className='hittaHitCon'>
        <div className='kartacamp'></div>
        <div className='kartaOvan'></div>
      </div>
    </>
  );
}

export default HittaHit;
