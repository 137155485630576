import React from "react";
import { useLanguage } from "./LanguageContext";
import English from "./Swedish"; // Import language-specific text objects
import Swedish from "./English";
import German from "./German";
function SixthComp() {
  const { language } = useLanguage();
  let texts;
  switch (language) {
    case "swedish":
      texts = English;
      break;
    case "german":
      texts = German;
      break;
    default:
      texts = Swedish;
      break;
  }
  const { vandra } = texts || {};
  return (
    <div className='hikeCon' id='VandringCon'>
      <div className='image trail'></div>
      <h2>{vandra.title}</h2>
      <div className='hikeConFirstChild'>
        <p>{vandra.text}</p>
      </div>
    </div>
  );
}

export default SixthComp;
