const German = {
  title:
    "Der Campingplatz für DICH mit Interesse an naturbezogenen Erlebnissen! Der Campingplatz hat ein persönliches und kostengünstiges Profil.",
  title2:
    "Bei uns können Sie Ihr Zelt, Wohnwagen oder Wohnmobil aufstellen - aber Sie können auch ein komfortables Mobilheim mieten.",
  attGora: {
    mccamp:
      "MC-Camp - Für diejenigen, die gerne Motorradfahren auf ausgezeichneten Waldwegen mit aufregenden Rastplätzen",
    bilvandring:
      "Auto/Wandern - Sie können auf dem Campingplatz übernachten, Ihr Auto parken, Informationen erhalten oder Essen zubereiten",
    mountainbike:
      "Mountainbiken - Sie können Ihr Fahrrad sicher über Nacht abstellen. Es gibt tolle Waldwege zum Radfahren",
    fiske:
      "Angeln - In der Nähe eines ausgezeichneten Angelteichs + des Vätternsees und vieler anderer Angelplätze",
    goldenbay:
      "Golden-Bay - Hunde sind willkommen und Sie können effektives Wassertraining machen",
    horsedriving:
      "Pferdefahren - Weidefläche für Ihr Pferd und Platz zum Aufstellen Ihres Wagens",
    funktionsstod:
      "Unterstützung bei Behinderungen - Praktische Unterstützung steht für körperlich mobile Personen mit anderen Behinderungen zur Verfügung",
    service:
      "Dienstleistungen - Müssen Sie Wäsche waschen oder trocknen? Es gibt Waschmaschinen und Trockeneinrichtungen",
    web: "Internet - Sie können sich einen Computer ausleihen oder sich selbst ins Internet einloggen",
  },
  stod: "Wir verfügen über professionelle Erfahrung in Naturfaktoren, die die Vitalität und Verbundenheit für Personen mit Behinderungen fördern. Für Gäste mit qualifiziertem medizinischem Bedarf können wir Unterstützung bis zum Niveau einer Distriktsschwester bieten.",
  fotvard:
    "Wenn unsere Füße uns auf aufregende Naturabenteuer führen, benötigen sie möglicherweise zusätzliche Pflege. Auf dem Campingplatz können Sie einen Termin bei einem erfahrenen und gut ausgebildeten Fußpflegespezialisten vereinbaren. Fortgeschrittene Fußpflege einschließlich Wundversorgung kann bis zum Niveau einer Krankenschwester angeboten werden.",
  overnattning: {
    title: "Budgetfreundliche Übernachtung!",
    underTitle:
      "Zelt, Wohnwagen, Wohnmobil – bringen Sie Ihr eigenes mit! Sie können auch einen komfortablen Wohnwagen oder Mobilheim mieten! Entdecken Sie aufregende markierte Wege im Urwald von Tiveden.",
    aktiviteter: {
      mccamp: "MC-Camp - Stellplatz für Ihr Motorrad",
      mountainbike: "Mountainbiken - Sie können Ihr Fahrrad abschließen",
      cykel: "Seiten-by-Side-Fahrrad - Für Menschen mit Behinderungen",
      horsedriving:
        "Pferdefahren - Parkplatz und Weidefläche für Pferd und Kutsche",
      goldenBay: "GoldenBay - Wasserspaß mit Ihrem Hund",
    },
    title2: {
      title: "Camping 'light'",
      text: "Zugang zu Hygiene-, Wäsche- und Trockeneinrichtungen. Outdoor-Küche; Regen- und windgeschützter Essbereich. Kinderinsel; Spiel und Abenteuer für die Kleinen.",
    },
  },
  MC: {
    title: "Motorrad, Mountainbike, Fahrrad",
    underTitle: "Zusammenfassung",
    text: "Aus ökologischer Sicht ist das Fahrrad das herausragendste Verkehrsmittel. Mit einem Standard-Fahrrad fahren wir die längsten Strecken mit minimaler Muskelkraft. Das Fahrrad hat eine enorme Entwicklung durchgemacht – und die heutigen Mountainbikes unterscheiden sich stark von ihren Anfängen. Das Motorrad hat sich von den frühen Leichtgewichten zu monumentalen Straßenkreuzern oder Rennmaschinen mit unglaublichen Höchstgeschwindigkeiten entwickelt.",
    text2:
      "Auf dem Tivedsportens Eco Camp gibt es spezielle Arrangements für Fahrrad-, Mountainbike- und Motorradfahren mit Optionen für grundlegende Service- und Reparaturleistungen sowie Fahrzeugabstellplätze in einem sicheren Bereich.",
  },
  cykel: {
    title: "Fahrrad",
    text: "Der Schwedenweg, der sich von Norden nach Süden durch das ganze Land erstreckt, führt direkt durch Håkantorp; Tivedsportens Eco Camp. Nach Norden können Sie nach Laxå, Karlskoga und weiter bis nach Norrbotten radeln. Im Osten erreichen Sie Askersund, Örebro und die Ostküste einschließlich unserer Hauptstadt Stockholm. Nach Süden führt die Reise durch Tived, Falköping, hinunter bis zum südlichsten Teil Schwedens. Im Westen führt der Schwedenweg nach Mariestad, Vänersborg und bis zur norwegischen Grenze.",
  },
  mountainbike: {
    title: "Mountainbike & Motorrad",
    text: "Für die sehr wertvollen Fahrräder gibt es einen abschließbaren Raum, der in Zusammenarbeit mit Mountainbike-Enthusiasten entwickelt wurde. In einem einfachen Hygienebereich gibt es Einrichtungen zum Duschen, Waschen und Trocknen von Kleidung.",
    text2:
      "Die Straße durch Håkantorp hinunter nach Tived ist eine sehr beliebte Motorradroute. Als wir die Mühlenhütte in Håkantorp gekauft haben, war unsere Absicht, einen Aktivitätsort für leichtes Outdoor-Leben in Tiveden zu schaffen - und Motorradfahrer sind eine große Besuchergruppe der verschiedenen Attraktionen im Urwald von Tiveden. Wir haben spezielle Parkplätze für Motorräder, neben denen Sie Ihr Zelt aufstellen können.",
  },
  animal: {
    title: "Tivedsporten Hund und Pferd",
    hundTitle: "Hund",
    textHund:
      "Tivedsporten liegt an einem kleinen Wasserlauf – einem ehemaligen Mühlenteich – der sich hervorragend zum Training von Apportieren und körperlichen Übungen für Hunde eignet – daher Golden-Bay – da wir selbst einen Golden Retriever haben.",
    hastTitle: "Pferd",
    textHast:
      "Tivedsporten umfasst ein kleines Feldgebiet + ein Hain, das für Pferde eingezäunt wurde. Es gibt Regen- und Windschutz. Tiveden verfügt über mehrere geeignete Routen für Pferdefuhrwerke wie Kutschfahrten und Vierradfahrzeuge. Wir haben nicht die Möglichkeit, Pferde selbst zu beherbergen – können jedoch in einigen Fällen den Kontakt vermitteln. Wir können auch den Kontakt zu einem Tierarzt vermitteln.",
  },
  vandra: {
    title: "Wandern",
    text: "Wandern bei Tivedsporten; Die Wege/Routen sind auf der großen Informationswand am Tivedsportens Eco Camp markiert. Sie können auch gedruckte Wanderanleitungen erhalten oder unsere IT-Hütte besuchen und online nach Informationen suchen. In der Nähe gibt es insgesamt 23 verschiedene Wanderwege.",
  },
  stod: {
    title: "Funktionale Unterstützung",
    text: "Wir arbeiten mit Naturtourismus, was bedeutet, dass jeder die Möglichkeit haben sollte, die Natur auf eine Weise zu erleben, die Spannung mit Demut und Verantwortung verbindet. Tivedsporten; Eco-Camp richtet sich an traditionelle Touristen, aber auch an Outdoor-Enthusiasten mit Behinderungen – insbesondere solche mit kognitiven Beeinträchtigungen. Kognitive Beeinträchtigungen beinhalten verschiedene mentale/gedankliche Schwierigkeiten. Innerhalb von Tivedsporten gibt es professionelle Expertise, insbesondere im Hinblick auf altersbedingte funktionale Veränderungen – Unterstützung für sie und ihre Familien, um positive Naturerlebnisse zu haben.",
  },
  fiske: {
    title: "Angeln",
    text: "Tivedsporten liegt dort, wo die Tived-Wälder beginnen, mit zahlreichen Wasserläufen. Es liegt in der Nähe der fischreichsten und schönsten Teile des Schärengartens des Vätterns. Seit dem Sommer 2014 stellen wir Angelscheine aus. Wenn Sie ein Schleppboot aufstellen oder andere Angelausrüstung zurücklassen müssen, gibt es dafür Platz. Wenn Sie Tivedsportens Eco Camp als Basis nutzen, haben Sie einfachen Zugang zu allen Formen des Angelns, schöner Landschaft und nur fünf Kilometer von der gemütlichen kleinen Hafenstadt Askersund entfernt! Tivedsportens Eco Camp arbeitet mit iFiske zusammen und unterstützt es.",
  },
  kontakt: {
    title: "Kontaktieren Sie mich gerne für weitere Informationen",
    tel: "Telefon",
    epost: "E-Mail",
  },
  navbar: {
    hem: "Startseite",
    aktiviteter: "Aktivitäten",
    information: "Informationen",
    mc: "MC, Mountainbike & Fahrrad",
    animal: "Hund & Pferd",
    hike: "Wandern",
    support: "Funktionale Unterstützung",
    fishing: "Angeln",
    contact: "Kontakt",
  },
};
export default German;
