import React from "react";

function SecondImages() {
  return (
    <div className='secondImagesCon'>
      <div className='fiskeArv'></div>
      <div className='tavlor'></div>
      <div className='baverkoja'></div>
    </div>
  );
}

export default SecondImages;
