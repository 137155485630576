import React, { useState, useRef } from "react";
import { useLanguage } from "./LanguageContext";
import English from "./English";
import Swedish from "./Swedish";
import German from "./German";

function Navbar() {
  const { language, setLanguage } = useLanguage();

  const scrollToTopCon = () => {
    const MCConConElement = document.getElementById("topCon");
    if (MCConConElement) {
      MCConConElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToSupportCon = () => {
    const supportConElement = document.getElementById("supportCon");
    if (supportConElement) {
      supportConElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToInformationCon = () => {
    const informationConElement = document.getElementById("informationCon");
    if (informationConElement) {
      informationConElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToMCCon = () => {
    const MCConConElement = document.getElementById("MCCon");
    if (MCConConElement) {
      MCConConElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToAnimalCon = () => {
    const MCConConElement = document.getElementById("animalCon");
    if (MCConConElement) {
      MCConConElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToVandringCon = () => {
    const MCConConElement = document.getElementById("VandringCon");
    if (MCConConElement) {
      MCConConElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToFiskeCon = () => {
    const MCConConElement = document.getElementById("fiskeCon");
    if (MCConConElement) {
      MCConConElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToKontaktCon = () => {
    const kontaktConElement = document.getElementById("kontaktCon");
    if (kontaktConElement) {
      kontaktConElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
  };

  const [isLinksVisible, setIsLinksVisible] = useState(false);

  const toggleLinks = () => {
    setIsLinksVisible((prev) => !prev);
  };
  let texts;

  switch (language) {
    case "english":
      texts = English;
      break;
    case "german":
      texts = German;
      break;
    default:
      texts = Swedish;
      break;
  }
  const { navbar } = texts || {};
  return (
    <div className='topnav'>
      <a href='#home' className='active'>
        <div className='bigLangCon'>
          <div className='langCon'>
            <p>Tivedsporten</p>
            <div
              className='swedish linkPush'
              onClick={() => handleLanguageChange("swedish")}
            ></div>
            <div
              className='german linkPush'
              onClick={() => handleLanguageChange("german")}
            ></div>
            <div
              className='english linkPush'
              onClick={() => handleLanguageChange("english")}
            ></div>
          </div>
          <button onClick={toggleLinks} className='icon'>
            <i className='fa fa-bars'></i>
          </button>
        </div>
      </a>

      {/* Conditionally render the links based on state */}
      {isLinksVisible && (
        <div id='myLinks'>
          <a href='#' onClick={scrollToTopCon}>
            {navbar.hem}
          </a>
          <a href='#contact' onClick={scrollToInformationCon}>
            {navbar.information}
          </a>
          <a href='#about' onClick={scrollToMCCon}>
            {navbar.mc}
          </a>
          <a href='#about' onClick={scrollToAnimalCon}>
            {navbar.animal}
          </a>
          <a href='#about' onClick={scrollToVandringCon}>
            {navbar.hike}
          </a>
          <a href='#about' onClick={scrollToSupportCon}>
            {navbar.support}
          </a>
          <a href='#about' onClick={scrollToFiskeCon}>
            {navbar.fishing}
          </a>
          <a href='#kontakt' onClick={scrollToKontaktCon}>
            {navbar.contact}
          </a>
        </div>
      )}
    </div>
  );
}

export default Navbar;
