import React from "react";

function FirstImages() {
  return (
    <div className='firstImagesCon'>
      <div className='stuga'></div>
      <div className='tommy'></div>
      <div className='spang'></div>
    </div>
  );
}

export default FirstImages;
