import React from "react";

function ThirdImages() {
  return (
    <div className='thirdImagesCon'>
      <div className='damm'></div>
      <div className='hastkorning'></div>
      <div className='hast'></div>
    </div>
  );
}

export default ThirdImages;
