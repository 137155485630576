import { LanguageProvider } from "./Components/LanguageContext";
import FirstComp from "./Components/FirstComp";
import SecondComp from "./Components/SecondComp";
import Navbar from "./Components/Navbar";
import ThirdComp from "./Components/ThirdComp";
import FourthComp from "./Components/FourthComp";
import FifthComp from "./Components/FifthComp";
import SixthComp from "./Components/SixthComp";
import SeventhComp from "./Components/SeventhComp";
import EighthComp from "./Components/EighthComp";
import Kontakt from "./Components/Kontakt";
import FirstImages from "./Components/FirstImages";
import SecondImages from "./Components/SecondImages";
import ThirdImages from "./Components/ThirdImages";
import HittaHit from "./Components/HittaHit";

function App() {
  return (
    <>
      <LanguageProvider>
        <Navbar />

        <div className='container'>
          <FirstComp />
          {/* <SecondComp /> */}
          <ThirdComp />
          <FirstImages />
          <FourthComp />
          <FifthComp />
          <SecondImages />
          <SixthComp />
          <SeventhComp />
          <ThirdImages />

          <EighthComp />
          <HittaHit />
          <Kontakt />
        </div>
      </LanguageProvider>
    </>
  );
}

export default App;
